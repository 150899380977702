import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ResizeObserver from 'resize-observer-polyfill';
import AudiRingsLogo from '../components/AudiRingsLogo';
import FormField from '../components/FormField';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import GoogleAuthSetup from '../components/GoogleAuthSetup';
import { request } from '../lib/apiRequestWrapper';
import { PERMISSIONS } from '../constants';

import { connect } from 'react-redux';
import { loadProfile } from '../redux/User';
const mapStateToProps = state => {
  return {
    isLoadingProfile: state.user.loading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadProfile: (cb, errCb, auth) => {dispatch(loadProfile(cb, errCb, auth));},
  }
}

const mobileBreakpoint = 600;

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      setupInfo: null,
      serverError: false,
      formTheme: null
    }
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(this.layoutUpdate);
    this.resizeObserver.observe(document.body);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(document.body);
  }

  layoutUpdate = (entries, observer) => {
    const {width} = entries[0].contentRect;
    if (width < mobileBreakpoint && this.state.formTheme !== "light") {
      this.setState({formTheme: "light"});
    }
    if (width >= mobileBreakpoint && this.state.formTheme === "light") {
      this.setState({formTheme: null});
    }
  }

  validateForm = (values) => {
    let errors = {};
    if (this.state.step === 1) {
      if (!values.email) { errors.email = "This field is required"; }
      if (!values.password) { errors.password = "This field is required"; }
    }
    if (this.state.step === 2 || this.state.step === 4) {
      if (!values.code) { errors.code = "This field is required"; }
    }
    return errors;
  }

  submitForm = (values, formikBag) => {
    this.setState({loading: true});
    if (this.state.step === 1) {
      this.login({email: values.email, password: values.password}, formikBag);
    }
    if (this.state.step === 2) {
      this.login2FA({
        userId: values.userId,
        code: values.code.trim(),
        userName: values.email,
        password: values.password
      }, formikBag);
    }
    if (this.state.step === 4) {
      this.validate2FASetup({
        userId: values.userId,
        code: values.code.trim(),
        twoFactorSecretKey: this.state.setupInfo?.secret,
        userName: values.email,
        password: values.password
      }, formikBag);
    }
  }
  
  login = (payload, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/1/account/token`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      // do 2FA
      if (this.state.serverError) { this.setState({serverError: false}); }
      formikBag.setValues({...payload, ...authData});
      if (authData.twoFactorEnabled) {
        this.setState({step: 2, loading: false});
      } else {
        this.triggerAppSetup(authData.userId, formikBag);
        this.setState({step: 3});
      }
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  login2FA = (payload, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/1/account/validateTwoFactorPin`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      // authentication success, load profile
      this.props.loadProfile(
        (userData) => {
          if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
            this.props.history.replace("/dealers");
          } else {
            this.props.history.replace("/entries");
          }
        },
        (error) => {
          this.handleServerError(error, formikBag);
        },
        authData
      );
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  validate2FASetup = (payload, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/1/account/validateTwoFactorSetup`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      // authentication success, load profile
      this.props.loadProfile(
        (userData) => {
          if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
            this.props.history.replace("/dealers");
          } else {
            this.props.history.replace("/entries");
          }
        },
        (error) => {
          this.handleServerError(error, formikBag);
        },
        authData
      );
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }
  
  triggerAppSetup = (uId, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/1/account/twofactorSetup?userId=${uId}`
    ).then((res) => {
      this.setState({loading: false, setupInfo: res, serverError: false});
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleServerError = (error, formikBag) => {
    console.log('handleServerError', error);
    let msg = "An error has occured";
    if (error.body && error.body.modelState) {
      formikBag?.setErrors(error.body.modelState);
      msg = false;
    } else if (error.body && error.body.message) {
      msg = error.body.message;
    }
    this.setState({loading: false, serverError: msg});
  }

  render() {
    const { step, formTheme } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{email: "", password: ""}}>
      {(formikBag) => (
        <Form className="">
          <div className="page-login">
            <AudiRingsLogo />
            {this.state.loading && <LoadingSpinner />}
            <div className="page-login__form h-100">
        
              <div className="d-flex flex-column justify-content-between justify-content-xsmall-start ps-xsmall-4 position-relative h-100">

                <header className="pt-5 pt-xsmall-3 px-3 pb-3">
                  <div className="text-end"></div>
                  <p className="aui-headline-4">Dealer Online Sales Portal</p>
                  <p className="aui-headline-2">Always connected</p>
                </header>

                <div className="d-flex flex-column justify-content-end justify-content-xsmall-start p-3 page-login__gradient">

                  {step === 1 && <>
                    <FormField name="email" label="Email" formikBag={formikBag} theme={formTheme} wrapperClassName="" />
                    <FormField name="password" label="Password" type="password" formikBag={formikBag} theme={formTheme} wrapperClassName="" />
                    <p className={cn("text-end mb-4", {"aui-color-text-light": formTheme === "light"})}>
                      <Link to="/forgot-password" className="forgot-pass-link">Forgot password?</Link>
                    </p>
                    {this.state.serverError && <p className="aui-color-text-red py-3">{this.state.serverError}</p>}
                    <Button isStretched type="submit" variant="primary" theme={formTheme}>Login</Button>
                  </>}
                  
                  {step === 2 && <>
                    <p>Enter the 6-digit code from your Google Authenticator app.</p>
                    <FormField name="code" label="2FA Code" formikBag={formikBag} />
                    <div className="text-right mt-4">
                      <Button isStretched type="submit" variant="primary" theme={formTheme}>Verify</Button>
                    </div>
                  </>}
              
                  {step === 3 && <>
                    <h5 className="aui-color-text-red mb-2 font-extended"><b>You need to enable 2FA</b></h5>
                    <p className={cn("font-default mb-2", {"aui-color-text-light": formTheme === "light"})}>In order to make your experience with us as safe as possible, you will need to use <b>Two-Factor Authentication (2FA)</b> to log in.</p>
                    <p className={cn("font-default mb-3", {"aui-color-text-light": formTheme === "light"})}>You will be using the Google Authenticator app, which will generate a temporary 6-digit code for you whenever you log in.</p>
                    <p className="text-end">
                      <Button type="button" variant="primary" theme={formTheme} onClick={()=>{
                        this.setState({step: 4});
                      }}>Continue</Button>
                    </p>
                  </>}
                  
                  {step === 4 && 
                    <GoogleAuthSetup
                      formikBag={formikBag}
                      setupInfo={this.state.setupInfo}
                      getNewCode={()=>{ this.triggerAppSetup(formikBag.values.userId); }}
                      formTheme={formTheme}
                    />
                  }
              
                  <p className="pt-2">
                    <Button
                      variant="text"
                      onClick={() => { this.props.history.push("/support") }}
                      className="support font-default"
                      theme={formTheme}
                    >
                      Support
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
