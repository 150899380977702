import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last name"),
  email: Yup.string().required().email().label("Email"),
  role: Yup.string().required().label("Role"),
  password: Yup.string().min(8).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/, {excludeEmptyString: true, message: "Password must contain at least 1 number, 1 uppercase letter, and 1 symbol"}).label("Password"),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match")
});

// password required for new user
export const newUserSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last name"),
  email: Yup.string().required().email().label("Email"),
  role: Yup.string().required().label("Role"),
  password: Yup.string().required().min(8).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/, {excludeEmptyString: true, message: "Password must contain at least 1 number, 1 uppercase letter, and 1 symbol"}).label("Password"),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match")
});

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string().required().min(8).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/, {excludeEmptyString: true, message: "Password must contain at least 1 number, 1 uppercase letter, and 1 symbol"}).label("Password"),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match")
});
