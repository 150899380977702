import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M3.5,7.5 L19.5,7.5 L18.1,5.5 L4.9,5.5 C4.9,5.5 3.5,7.5 3.5,7.5 Z"/>
          <path d="M5.5,9 L5.5,19.5 C5.5,20 5.9,20.5 6.5,20.5 L16.5,20.5 C17,20.5 17.5,20 17.5,19.5 L17.5,9"/>
          <path d="M11.5,5.5 L11.5,3.5 M10,3.5 L13,3.5" strokeLinejoin="round"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M5 12.5L42 12.5 38.6 7.5 8.4 7.5z"/>
        <path d="M37.5,16 L37.5,41 C37.5,42.4 36.4,43.5 35,43.5 L12,43.5 C10.6,43.5 9.5,42.4 9.5,41 L9.5,16"/>
        <path d="M23.5,7.4 L23.5,4.4 M21,4.5 L26,4.5" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};
