import { useState } from "react";
import Button from '../../audi-ui-components/Button';
import Carousel from 'react-bootstrap/Carousel';
import appBtn from './google_auth_app_button.png';
import FormField from '../FormField';
import IconForward from '../../audi-ui-components/icons/Forward';
import IconArrowUp from '../../audi-ui-components/icons/ArrowStraightUp.js';
import cn from 'classnames';

const GoogleAuthSetup = ({ formikBag, setupInfo, getNewCode = ()=>{}, formTheme }) => {
  
  const [slideIdx, setSlideIdx] = useState(0);
  
  return (<>
    <h5 className={cn("font-extended mb-3", {"aui-color-text-light": formTheme === "light"})}><b>Google Authenticator setup {slideIdx + 1}/5</b></h5>
    <Carousel interval={null} activeIndex={slideIdx} onSelect={setSlideIdx} controls={false} indicators={false} wrap={false} className={cn({"aui-color-text-light": formTheme === "light"})}>
      <Carousel.Item>
        <p>Download the <b>Google Authenticator</b> app to your phone.</p>
        <p>Google Authenticator is available for <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer" className="aui-textlink">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US" target="_blank" rel="noreferrer" className="aui-textlink">Android</a>.</p>
      </Carousel.Item>
      <Carousel.Item>
        <p>Open the app and tap the "Get started" button. You will have the option to either log in with your Google account or use the app without logging in. If you do log in, Google Authenticator will save your 2FA data for you.</p>
      </Carousel.Item>
      <Carousel.Item>
        <p>To add an account to the app, tap the <img src={appBtn} alt="+" style={{width: "1.2em", height: "1.2em"}} /> button in the bottom-right. Select the option to Scan a QR Code using your phone, or if you are reading this on your phone select the option to Enter a Setup Key.</p>
      </Carousel.Item>
      <Carousel.Item>
        <p>Scan the below QR Code: <br /><img src={setupInfo?.qrCodeSetupImageUrl} alt="QR Code" className="my-4" />
        <br />or use the following setup key<br /> <b style={{wordWrap: "anywhere"}}>{setupInfo?.manualEntryKey}</b></p>
        <p><Button type="button" variant="text" theme={formTheme} onClick={getNewCode}>Get a new code</Button></p>
      </Carousel.Item>
      <Carousel.Item>
        <p className="mb-1">Google Authenticator will generate a 6-digit code, enter it below and click Verify</p>
        <FormField name="code" label="2FA Setup Code" formikBag={formikBag} theme={formTheme} />
        <div className="text-end mt-3">
          <Button type="submit" variant="primary" theme={formTheme}>Verify</Button>
        </div>
        <div className="mb-1">
          <Button type="button" className="backbtn" theme={formTheme} onClick={()=>{ setSlideIdx(slideIdx - 1); formikBag.setErrors({}); }}><IconArrowUp small /><span className="visually-hidden">previous</span></Button>
        </div>
      </Carousel.Item>
    </Carousel>
    {slideIdx < 4 && <div className="d-flex justify-content-between align-items-center mb-3">
      <Button type="button" className="backbtn" theme={formTheme} onClick={()=>{ setSlideIdx(slideIdx - 1); }} disabled={slideIdx === 0}><IconArrowUp small /><span className="visually-hidden">previous</span></Button>
      <Button type="button" theme={formTheme} variant="primary" onClick={()=>{ setSlideIdx(slideIdx + 1); }} icon={<IconForward small />} iconPosition="right">Next</Button>
    </div>}
  </>);
};

export default GoogleAuthSetup;
