import { STATUS, ROLES } from '../constants';

const SET_PAGE_ENTRIES = "UI/SET_PAGE_ENTRIES";
const SET_SEARCH_ENTRIES = "UI/SET_SEARCH_ENTRIES";
const SET_FILTER_ENTRIES_START = "UI/SET_FILTER_ENTRIES_START";
const SET_FILTER_ENTRIES_END = "UI/SET_FILTER_ENTRIES_END";
const SET_FILTER_ENTRIES_STATUS = "UI/SET_FILTER_ENTRIES_STATUS";
const SET_FILTER_ENTRIES_STATUS_PREORDER = "UI/SET_FILTER_ENTRIES_STATUS_PREORDER";
export const SET_FILTER_TAB = "UI/SET_FILTER_TAB";
const SET_SORT_ENTRIES = "UI/SET_SORT_ENTRIES";

const SET_PAGE_PAYOUTS = "UI/SET_PAGE_PAYOUTS";
const SET_FILTER_PAYOUTS_START = "UI/SET_FILTER_PAYOUTS_START";
const SET_FILTER_PAYOUTS_END = "UI/SET_FILTER_PAYOUTS_END";

const SET_SORT_SUMMARY = "UI/SET_SORT_SUMMARY";

const SET_SORT_USERS = "UI/SET_SORT_USERS";
const SET_FILTER_USERS_ROLE = "UI/SET_FILTER_USERS_ROLE";

const SET_CURRENT_DID = "UI/SET_CURRENT_DID";

const SET_SEARCH = "UI/SET_SEARCH";

export function setPageEntries(p) {
  return {type: SET_PAGE_ENTRIES, payload: p};
}

export function setEntriesFilters(type, v) {
  if (type === "search") {
    return {type: SET_SEARCH_ENTRIES, payload: v};
  }
  if (type === "start") {
    return {type: SET_FILTER_ENTRIES_START, payload: v};
  }
  if (type === "end") {
    return {type: SET_FILTER_ENTRIES_END, payload: v};
  }
  if (type === "status") {
    return {type: SET_FILTER_ENTRIES_STATUS, payload: v};
  }
  if (type === "statuspreorder") {
    return {type: SET_FILTER_ENTRIES_STATUS_PREORDER, payload: v};
  }
  if (type === "tab") {
      return {type: SET_FILTER_TAB, payload: v};
  }
  if (type === "sort") {
    return {type: SET_SORT_ENTRIES, payload: v};
  }
}

export function setSummaryFilters(type, v) {
  if (type === "sort") {
    return {type: SET_SORT_SUMMARY, payload: v};
  }
}

export function setUserFilters(type, v) {
  if (type === "sort") {
    return {type: SET_SORT_USERS, payload: v};
  }
  if (type === "role") {
    return {type: SET_FILTER_USERS_ROLE, payload: v};
  }
}

export function setPagePayouts(p) {
  return {type: SET_PAGE_PAYOUTS, payload: p};
}

export function setPayoutsFilters(type, v) {
  if (type === "start") {
    return {type: SET_FILTER_PAYOUTS_START, payload: v};
  }
  if (type === "end") {
    return {type: SET_FILTER_PAYOUTS_END, payload: v};
  }
}

export function setCurrentDealerId(id) {
  return {type: SET_CURRENT_DID, payload: id};
}

export function setSearch(searchText) {
  return {type: SET_SEARCH, payload: searchText};
}

const defaultState = {
  currentDealerId: null,

  pageSize: 10,

  search: "",

  pageEntries: 1,
  searchEntries: "",
  filterEntriesDateStart: null,
  filterEntriesDateEnd: null,
  filterEntriesStatus: [
    `(status eq '${STATUS.PENDING}')`,
    `(status eq '${STATUS.PROGRESS}')`,
    `(status eq '${STATUS.CONTACTED}')`,
    `(status eq '${STATUS.SOLD}')`,
    `(status eq '${STATUS.RELEASED}')`,
    `(status eq '${STATUS.CLOSED}')`
  ],
  filterEntriesStatusPreorder: [
    `(vehicleReservationStatus eq '${STATUS.PENDING}')`,
    `(vehicleReservationStatus eq '${STATUS.PROGRESS}')`,
    `(vehicleReservationStatus eq '${STATUS.CONTACTED}')`,
    `(vehicleReservationStatus eq '${STATUS.SOLD}')`,
    `(vehicleReservationStatus eq '${STATUS.CLOSED}')`
  ],
  filterEntriesTab: "new", // "new" / "used" / "preorder"
  sortEntries: "createDate desc", // "status asc/desc", "stockNumber asc/desc"

  sortSummary: "name asc", // "name desc"

  filterUsersRole: [
    `(role eq '${ROLES.ADMIN}')`,
    `(role eq '${ROLES.AUDI_ADMIN}')`,
    `(role eq '${ROLES.REG_MNGR}')`,
    `(role eq '${ROLES.GROUP_MNGR}')`,
    `(role eq '${ROLES.DEALER_ADMIN}')`,
    `(role eq '${ROLES.DEALER}')`
  ],
  sortUsers: "lastName asc",

  pagePayouts: 1,
  filterPayoutsDateStart: null,
  filterPayoutsDateEnd: null,
};

export function uiReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PAGE_ENTRIES:
      return Object.assign({}, state, {
        pageEntries: action.payload
      });
    case SET_SEARCH_ENTRIES:
      return Object.assign({}, state, {
        searchEntries: action.payload,
        pageEntries: 1
      });
    case SET_FILTER_ENTRIES_START:
      return Object.assign({}, state, {
        filterEntriesDateStart: action.payload,
        pageEntries: 1
      });
    case SET_FILTER_ENTRIES_END:
      return Object.assign({}, state, {
        filterEntriesDateEnd: action.payload,
        pageEntries: 1
      });
    case SET_FILTER_ENTRIES_STATUS:
      return Object.assign({}, state, {
        filterEntriesStatus: action.payload,
        pageEntries: 1
      });
    case SET_FILTER_ENTRIES_STATUS_PREORDER:
      return Object.assign({}, state, {
        filterEntriesStatusPreorder: action.payload,
        pageEntries: 1
      });
    case SET_FILTER_TAB:
      return Object.assign({}, state, {
          filterEntriesTab: action.payload,
          pageEntries: 1
      });
    case SET_SORT_ENTRIES:
      return Object.assign({}, state, {
        sortEntries: action.payload,
        pageEntries: 1
      });

    case SET_PAGE_PAYOUTS:
      return Object.assign({}, state, {
        pagePayouts: action.payload
      });
    case SET_FILTER_PAYOUTS_START:
      return Object.assign({}, state, {
        filterPayoutsDateStart: action.payload
      });
    case SET_FILTER_PAYOUTS_END:
      return Object.assign({}, state, {
        filterPayoutsDateEnd: action.payload
      });

    case SET_SORT_SUMMARY:
      return Object.assign({}, state, {
        sortSummary: action.payload
      });

    case SET_SORT_USERS:
      return Object.assign({}, state, {
        sortUsers: action.payload
      });
    case SET_FILTER_USERS_ROLE:
      return Object.assign({}, state, {
        filterUsersRole: action.payload
      });

    case SET_CURRENT_DID:
      return Object.assign({}, state, {
        currentDealerId: action.payload
      });

    case SET_SEARCH:
      return Object.assign({}, state, {
        search: action.payload
      });

    default:
      return state;
  }
}
