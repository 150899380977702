import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import EntryTable from './EntryTable';
import ExportEntries from '../../components/ExportEntries';
import LoadingSpinner from '../../components/LoadingSpinner';
import DateField from '../../components/DateField';
import CheckboxFieldset from '../../components/CheckboxFieldset';
import Dropdown from '../../audi-ui-components/Dropdown';
import IconForward from '../../audi-ui-components/icons/Forward';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';
import IconSearch from '../../audi-ui-components/icons/Search';
import IconCancel from '../../audi-ui-components/icons/Cancel';
import { STATUS, STATUS_LABELS, STATUS_LIST, STATUS_LIST_PREORDER, PERMISSIONS } from '../../constants';
import _find from 'lodash/find';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { request } from '../../lib/apiRequestWrapper';

import { connect } from 'react-redux';
import { setEntriesFilters, setPageEntries, setCurrentDealerId } from '../../redux/Ui';
import { getEntries, getDealers } from '../../redux/Data';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    entries: state.data.entries,
    entriesCount: state.data.entriesCount,
    pageSize: state.ui.pageSize,
    pageEntries: state.ui.pageEntries,
    searchEntries: state.ui.searchEntries,
    filterEntriesDateStart: state.ui.filterEntriesDateStart,
    filterEntriesDateEnd: state.ui.filterEntriesDateEnd,
    filterEntriesStatus: state.ui.filterEntriesStatus,
    filterEntriesStatusLength: state.ui.filterEntriesStatus.length, // triggers rerender
    filterEntriesStatusPreorder: state.ui.filterEntriesStatusPreorder,
    filterEntriesStatusPreorderLength: state.ui.filterEntriesStatusPreorder.length, // triggers rerender
    filterEntriesTab: state.ui.filterEntriesTab,
    sortEntries: state.ui.sortEntries,
    isLoadingEntries: state.data.isLoadingEntries,
    dealers: state.data.dealers,
    isLoadingDealers: state.data.isLoadingDealers
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setEntriesFilters: (type, v) => {dispatch(setEntriesFilters(type, v));},
    setPageEntries: (p) => {dispatch(setPageEntries(p));},
    setCurrentDealerId: (id) => {dispatch(setCurrentDealerId(id));},
    getEntries: (dealerId, page) => {dispatch(getEntries(dealerId, page));},
    getDealers: (cb) => {dispatch(getDealers(cb));},
  }
}

const sortOpts = [
  {value: "createDate asc", label: "Date (asc)", jsxLabel: (<>Date <IconUp small /></>)},
  {value: "createDate desc", label: "Date (desc)", jsxLabel: (<>Date <IconDown small /></>)},
  {value: "status asc", label: "Status (asc)", jsxLabel: (<>Status <IconUp small /></>)},
  {value: "status desc", label: "Status (desc)", jsxLabel: (<>Status <IconDown small /></>)},
];
const statusOpts = STATUS_LIST.map(s => {
  return {value: `(status eq '${s}')`, label: STATUS_LABELS[s]};
});
const statusPreorderOpts = STATUS_LIST_PREORDER.map(s => {
  return {value: `(vehicleReservationStatus eq '${s}')`, label: STATUS_LABELS[s]};
});

class ListEntries extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileFiltersOpen: false,
      activeMobileFilter: null,
      currentDealerName: ""
    }
    this._debouncedFetch = _debounce(() => { props.getEntries(props.match.params.dealerId); }, 1000);
  }

  componentDidMount() {
    const {match} = this.props;
    this.props.getEntries(match.params.dealerId);
    if (match.params.dealerId) {
      this.props.setCurrentDealerId(match.params.dealerId);
      if (!this.props.dealers || !this.props.dealers.length) {
        this.props.getDealers((data) => {
          let d = _find(data, {id: +match.params.dealerId}) || {};
          this.setState({currentDealerName: d.name});
        });
      } else {
        let d = _find(this.props.dealers, {id: +match.params.dealerId}) || {};
        this.setState({currentDealerName: d.name});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sortEntries !== prevProps.sortEntries ||
      this.props.pageEntries !== prevProps.pageEntries ||
      this.props.filterEntriesDateStart !== prevProps.filterEntriesDateStart ||
      this.props.filterEntriesDateEnd !== prevProps.filterEntriesDateEnd ||
      this.props.filterEntriesTab !== prevProps.filterEntriesTab ||
      this.props.filterEntriesStatusLength !== prevProps.filterEntriesStatusLength ||
      this.props.filterEntriesStatusPreorderLength !== prevProps.filterEntriesStatusPreorderLength
    ) {
      this._debouncedFetch();
    }
  }

  toggleMobileFilter = (n) => {
    if (this.state.mobileFiltersOpen && n === this.state.activeMobileFilter) {
      this.setState({
        activeMobileFilter: null,
        mobileFiltersOpen: false
      });
    } else {
      this.setState({
        activeMobileFilter: n,
        mobileFiltersOpen: true
      });
    }
  }

  handleClickEntry = (id, path = "entry") => {
    this.props.history.push(`/${path}/${id}`);
  }

  handlePaginationChange = (idx) => {
    window.scrollTo(0, 0);
    this.props.setPageEntries(idx + 1);
  }

  render() {
    const { userData, entries, filterEntriesTab, match } = this.props;
    const { activeMobileFilter, currentDealerName } = this.state;
    return (
      <main className="page-list">

        {this.props.isLoadingEntries &&
          <LoadingSpinner type={!entries || entries.length < 1 ? 'overlay' : 'fixed'}>Loading</LoadingSpinner>
        }

        {match.params.dealerId && <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/dealers">Dealers</Link></li>
            <li><IconForward small /></li>
            <li className="breadcrumb-item active" aria-current="page"><b>{currentDealerName}</b></li>
          </ol>
        </nav>}

        <div className="row">

          <div className="filters col-12 col-medium-3 ps-medium-4 order-medium-last">
            <div className="d-medium-none mobile-filter-links">
              <button onClick={() => { this.toggleMobileFilter("filter"); }} className={classnames({"active": activeMobileFilter === "filter"})}>Filter</button>
              <button onClick={() => { this.toggleMobileFilter("sort"); }} className={classnames({"active": activeMobileFilter === "sort"})}>Sort</button>
              <button onClick={() => { this.toggleMobileFilter("export"); }} className={classnames({"active": activeMobileFilter === "export"})}>Export</button>
            </div>

            <div className={classnames("filter", {"open": activeMobileFilter === "sort"})}>
              <div className="filter-heading d-none d-medium-block">Sort</div>
              <div className="filter-inner">
                <Dropdown name="sortEntries"
                  label="Sort entries"
                  value={this.props.sortEntries}
                  options={sortOpts}
                  onChange={(v) => { this.props.setEntriesFilters("sort", v); }}
                  className="hide-floating-label has-icons"
                />
              </div>
            </div>

            <div className={classnames("filter", {"open": activeMobileFilter === "filter"})}>
              <div className="filter-heading d-none d-medium-block">Filter</div>
              <div className="filter-inner">
                {filterEntriesTab !== "preorder" && <CheckboxFieldset name="filterEntriesStatus"
                  label="Status"
                  value={this.props.filterEntriesStatus}
                  options={statusOpts}
                  onChange={(v) => { this.props.setEntriesFilters("status", v); }}
                />}
                {filterEntriesTab === "preorder" && <CheckboxFieldset name="filterEntriesStatusPreorder"
                  label="Status"
                  value={this.props.filterEntriesStatusPreorder}
                  options={statusPreorderOpts}
                  onChange={(v) => { this.props.setEntriesFilters("statuspreorder", v); }}
                />}
                <DateField name="startDate"
                  label="Start date"
                  value={this.props.filterEntriesDateStart}
                  popperPlacement="left"
                  onChange={(v) => { this.props.setEntriesFilters("start", v); }}
                />
                <DateField name="endDate"
                  label="End date"
                  value={this.props.filterEntriesDateEnd}
                  popperPlacement="left"
                  onChange={(v) => { this.props.setEntriesFilters("end", v); }}
                />
              </div>
            </div>

            <ExportEntries
              isOpen={activeMobileFilter === 'export'}
              dealerId={match.params.dealerId}
              tab={filterEntriesTab}
              dealerName={match.params.dealerId ? currentDealerName : userData.dealerName}
              filterStatus={filterEntriesTab === "preorder" ? this.props.filterEntriesStatusPreorder : this.props.filterEntriesStatus}
              filterDateStart={this.props.filterEntriesDateStart}
              filterDateEnd={this.props.filterEntriesDateEnd}
              sortEntries={filterEntriesTab !== "preorder" ? this.props.sortEntries : null}
              sortReservations={filterEntriesTab === "preorder" ? this.props.sortEntries : null}
            >
              <p className="mt-2">Filters selected will be applied to the export.</p>
            </ExportEntries>

          </div>

          <div className="col-12 col-medium-9">

            <div className="row no-gutters tabs">
                {userData.view?.indexOf("new") > -1 && <div className="col">
                    <button
                        onClick={() => { this.props.setEntriesFilters("tab", "new"); }}
                        className={classnames("tab aui-button font-extended", {"active": filterEntriesTab === "new"})}
                    >
                        New Cars
                    </button>
                </div>}
                {userData.view?.indexOf("used") > -1 && <div className="col">
                    <button
                        onClick={() => { this.props.setEntriesFilters("tab", "used"); }}
                        className={classnames("tab aui-button font-extended", {"active": filterEntriesTab === "used"})}
                    >
                        Demo &amp; Used Cars
                    </button>
                </div>}
                <div className="col">
                    <button
                        onClick={() => { this.props.setEntriesFilters("tab", "preorder"); }}
                        className={classnames("tab aui-button font-extended", {"active": filterEntriesTab === "preorder"})}
                    >
                        Reservations
                    </button>
                </div>
            </div>

            <EntryTable
              data={entries}
              tab={filterEntriesTab}
              sort={this.props.sortEntries}
              onSort={(s) => { this.props.setEntriesFilters("sort", s); }}
              onClickRow={this.handleClickEntry}
              onClickPage={this.handlePaginationChange}
              page={this.props.pageEntries}
              count={this.props.entriesCount}
              pageSize={this.props.pageSize}
              isLoading={this.props.isLoadingEntries}
            />

          </div>

        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListEntries);
