import React from 'react';
import classnames from 'classnames';
import Button from '../audi-ui-components/Button';
import { request } from '../lib/apiRequestWrapper';

class ExportEntries extends React.Component {
  
  handleExportEntries = () => {
    let q = `?$orderby=${this.props.sortEntries}`;
    let filters = [];
    if (this.props.dealerId) {
      filters.push(`dealerId eq ${this.props.dealerId}`);
    }
    if (this.props.tab === "new") {
      filters.push("usedCar eq false");
    }
    if (this.props.tab === "used") {
      filters.push("usedCar eq true");
    }
    this.exportCsv(q, filters);
  }
  
  handleExportReservations = () => {
    let q = `?$orderby=${this.props.sortReservations}`;
    let filters = [];
    if (this.props.dealerId) {
      filters.push(`preferredDealerId eq ${this.props.dealerId}`);
    }
    this.exportCsv(q, filters, "reservations");
  }

  exportCsv = (q = "?", filters = [], type = "posts") => {
    const {dealerId, filterStatus, filterDateStart, filterDateEnd, sortEntries} = this.props;

    if (filterStatus && filterStatus.length) {
      let s = filterStatus.join(' or ');
      filters.push(`(${s})`);
    }
    if (filterDateStart) {
      let ds = moment(filterDateStart).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') ge ${ds}`);
    }
    if (filterDateEnd) {
      let de = moment(filterDateEnd).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') le ${de}`);
    }
    if (filters.length) {
      let f = filters.join(' and ');
      q = q.concat(`&$filter=${f}`);
    }
    request(
      `${process.env.RAZZLE_API}/1/${type}/export${q}`
    ).then((res) => {
      res.text().then(csvString => {
        // console.log(csvString);
        let d = new Date();
        let dString = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        let fName = type === "posts" ? "all-sales" : "all-reservations";
        if (this.props.tab) {
          fName = this.props.tab === "new" ? "new-cars" : this.props.tab === "used" ? "used-cars" : "reservations";
        }
        if (this.props.dealerName) {
          fName = this.props.dealerName.replace(" ", "-") + "_" + fName;
        }
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
        pom.setAttribute('download', `VTP_${fName}_${dString}.csv`);
        if (document.createEvent) {
          var e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          pom.dispatchEvent(e);
        }
        else {
          pom.click();
        }
      });
    }).catch((error) => {
      console.error(error);
      // let msg = "An error has occured";
      // if (error.body && error.body.message) {
      //   msg = error.body.message;
      // }
      // this.setState({isLoading: false, serverError: msg});
    });
  }

  render() {
    const { tab } = this.props;
    return (
      <div className={classnames("filter", {"open": this.props.isOpen})}>
        <div className="filter-heading d-none d-medium-block">Export to CSV</div>
        <div className="filter-inner">
          {this.props.sortEntries && <p className="mb-3">
            <Button variant="primary" onClick={this.handleExportEntries}>Export {!tab && 'All Sales'}{tab === "new" && 'New Car Sales'}{tab === "used" && 'Used Car Sales'}</Button>
          </p>}
          {this.props.sortReservations && <p>
            <Button variant="primary" onClick={this.handleExportReservations}>Export Reservations</Button>
          </p>}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ExportEntries;
