import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Button from '../../audi-ui-components/Button';
import { STATUS, STATUS_LIST_PREORDER, STATUS_LABELS } from '../../constants';
import queryString from 'query-string';

class EntryRow extends React.Component {
  render() {
    const { entry, tab } = this.props;
    let date = entry.createDate ? moment(entry.createDate).format("MMM D") : "0";
    let time = entry.createDate ? moment(entry.createDate).format("h:mma") : "0";
    let img = entry.imageUrl ? entry.imageUrl : false;
    if (img) {
      let i = img.indexOf("?");
      if (i === -1) {
        img = img + "?wid=200";
      } else {
        let qs = queryString.parse(img.substring(i));
        qs.wid = 200;
        img = img.substring(0,i) + "?" + queryString.stringify(qs);
      }
    }
    return (
      <div className="row mx-n1 list-item list-item__entry">

        {entry.requestType === "Enquiry" && entry.isReserved && <span className="reserved-dot" title="This car is reserved" />}

        <div className="col-2 px-1 align-self-center">
          <b>{date}</b><br />{time}
        </div>

        <div className="col-2 px-1 align-self-center">
            {img && <div className="vehicle-img" style={{backgroundImage: `url("${img}")`}} />}
        </div>

        {tab !== "preorder" && <div className="col-5 col-small-4 px-1 align-self-center">
          {entry.requestType === "Reserve" && <b className="aui-color-text-red">Order</b>}
          {entry.requestType === "Enquiry" && <b>Enquiry</b>}
          <br />
          <b>{entry.modelYear} {entry.model}</b><br />
          {entry.customer?.firstName} {entry.customer?.lastName}, {entry.customer?.postcode}
        </div>}
        
        {tab === "preorder" && <div className="col-5 col-small-6 px-1 align-self-center">
          Reservation
          <br />
          <b>{entry.modelYear} {entry.modelName}</b><br />
          {entry.firstName} {entry.lastName}, {entry.postcode}
        </div>}

        {tab !== "preorder" && <div className="col-2 d-none d-small-block px-1 align-self-center">
          {entry.stockNumber}
        </div>}

        {tab !== "preorder" && <div className="col-3 col-small-2 px-1 text-end align-self-center">
          <Button
            variant="primary"
            isStretched
            className={`entry-cta ${entry.status?.toLowerCase()}`}
            onClick={() => { this.props.onClick(entry.postId); }}
          >
            {STATUS_LABELS[entry.status]}
          </Button>
        </div>}
        {tab === "preorder" && <div className="col-3 col-small-2 px-1 text-end align-self-center">
          <Button
            variant="primary"
            isStretched
            className={`entry-cta ${entry.vehicleReservationStatus?.toLowerCase()}`}
            onClick={() => { this.props.onClick(entry.vehicleReservationId, "preorder"); }}
          >
            {STATUS_LABELS[entry.vehicleReservationStatus]}
          </Button>
        </div>}

      </div>
    );
  }
}

export default EntryRow;
