import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import IconCancel from '../audi-ui-components/icons/Cancel';
import IconCaution from '../audi-ui-components/icons/Caution';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import { STATUS, STATUS_LIST_PREORDER, STATUS_LABELS, FIELD_RADIOS, ROLES, PERMISSIONS } from '../constants';
import { request } from '../lib/apiRequestWrapper';
import { formatDollar } from '../lib/helpers';

import { connect } from 'react-redux';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    currentDealerId: state.ui.currentDealerId
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

class EntryPreorder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      entry: {},
      isLoadingEntry: true,
      fetchError: null,
      isSubmitting: false,
      serverError: null,
      updateSuccess: false
    }
  }

  componentDidMount() {
    const {match, userData} = this.props;
    if (match.params.entryId) {
      request(
        `${process.env.RAZZLE_API}/1/reservations?$filter=vehicleReservationId eq ${match.params.entryId}`
      ).then((data) => {
        let item = data.items[0]
        this.setState({entry: item, isLoadingEntry: false});
        // update status
        if (item.vehicleReservationStatus === STATUS.PENDING && (PERMISSIONS.CHANGE_STATUS.indexOf(userData.role) > -1)) {
          request(
            `${process.env.RAZZLE_API}/1/reservations/${match.params.entryId}`,
            {
              method: "PATCH",
              body: JSON.stringify({status: STATUS.PROGRESS}),
            }
          ).then((res) => {
            this.setState({entry: res});
          }).catch((error) => {
            console.error(error);
          });
        }
      }).catch((error) => {
        console.error(error);
        this.setState({isLoadingEntry: false, fetchError: error.status});
      });
    }
  }

  handleClose = () => {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1 && this.props.currentDealerId) {
      this.props.history.push(`/entries/${this.props.currentDealerId}`);
    } else {
      this.props.history.push("/entries");
    }
  }
  
  submitForm = (values, formikBag) => {
    const {match} = this.props;
    const {entry} = this.state;
    this.setState({isSubmitting: true, serverError: null, updateSuccess: false});
    request(
      `${process.env.RAZZLE_API}/1/reservations/${match.params.entryId}`,
      {
        method: "PATCH",
        body: JSON.stringify({status: values.status}),
      }
    ).then((res) => {
      // console.log(res);
      this.setState({entry: res, updateSuccess: true, isSubmitting: false});
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    });
  }

  render() {
    const { userData } = this.props;
    const { entry, isLoadingEntry, fetchError } = this.state;
    let img = entry.imageUrl ? entry.imageUrl : "";
    let config = entry.vehicleConfiguration ? JSON.parse(entry.vehicleConfiguration) : {};
    return (
      <main className="page-entry">
        {isLoadingEntry && <LoadingSpinner />}

        {!fetchError && <div className="row">

          <div className="col-12 col-medium-9">
            <p><b>&nbsp;</b></p>
            <hr className="mb-3 d-none d-medium-block" />

            <div className="row entry-vehicle">
              <div className="col col-medium-7">
                <div className="mb-3">
                  <p className="aui-headline-4">{entry.modelYear} {entry.modelName}</p>
                  <p>{config.modelCode}</p>
                </div>
                <p>Manufacturer’s Recommended Drive Away Price <b>{entry.driveAwayPrice}</b></p>
              </div>
              <div className="col-auto order-medium-last">
                <button onClick={this.handleClose} className="plain-button detail-close">
                  <span className="svg-wrapper"><IconCancel large /></span><br />close
                </button>
              </div>
              <div className="col-12 col-medium">
                <div className="vehicle-img" style={{backgroundImage: `url("${img}")`}} />
              </div>
            </div>

            <div className="entry-details">
              <hr className="my-3 d-none d-medium-block" />
              <p className="mt-3 mt-medium-none">Reservation</p>
              <hr className="my-3 d-medium-none" />
              <p>Customer: {entry.firstName} {entry.lastName}</p>
              <p>Email: {entry.email}</p>
              <p>Phone: {entry.mobile}</p>
              <p>Postcode: {entry.postcode}</p>

              <hr className="my-3" />
              <p className="aui-headline-5 mb-2">Vehicle configuration</p>
              <p>Body type: {config.shape}</p>
              <p>Drive type: {config.driveType}</p>
              <p>Colour: {config.exteriorColourCode} | {config.exteriorColour}</p>
              <p>Interior: {config.interiorColourCode} | {config.interiorColour}</p>
              <p>Optional equipment:</p>
              <ul>
                {config.options?.map((opt,i) => <li key={`opt${i}`}>{opt.name} ({opt.codes.replaceAll(",", ", ")})</li>)}
              </ul>
            </div>

          </div>

          <div className="col-12 col-medium-3 ps-medium-5">
            <p className="d-none d-medium-block"><b>Status</b></p>
            <hr className="my-3 mt-medium-0" />
            <p className="mb-5">
              <b>
                <span className="d-medium-none">Status: </span>
                <span className={classnames({
                  "aui-color-text-red": entry.vehicleReservationStatus === STATUS.PENDING,
                  "aui-color-text-green": entry.vehicleReservationStatus === STATUS.PROGRESS
                })}>{STATUS_LABELS[entry.vehicleReservationStatus]}</span>
              </b>
            </p>
            {PERMISSIONS.CHANGE_STATUS.indexOf(userData.role) > -1 && entry.vehicleReservationStatus !== STATUS.SOLD && entry.vehicleReservationStatus !== STATUS.CLOSED && <Formik onSubmit={this.submitForm} initialValues={{}}>
              {(formikBag) => (
                <Form>
                  <FormField name="status"
                    label="Change status?"
                    fieldType={FIELD_RADIOS}
                    options={[
                      {label: STATUS_LABELS[STATUS.CONTACTED], value: STATUS.CONTACTED, disabled: entry.vehicleReservationStatus === STATUS.CONTACTED},
                      {label: STATUS_LABELS[STATUS.SOLD], value: STATUS.SOLD},
                      {label: STATUS_LABELS[STATUS.CLOSED], value: STATUS.CLOSED}
                    ]}
                    formikBag={formikBag}
                  />
                  <p className="my-3">
                    <Button type="submit" variant="primary" disabled={!formikBag.dirty}>Submit</Button>
                  </p>
                </Form>
              )}
            </Formik>}
            {this.state.updateSuccess && <div className="my-5">
              <p className="aui-color-text-green mb-1">The status has been updated</p>
              {entry.status === STATUS.SOLD && <p className=""><b><IconCaution small style={{verticalAlign: "text-bottom"}} /> Please update status to sold in IDS.</b></p>}
            </div>}
          </div>

        </div>}

        {fetchError && <div className="py-5">
          {fetchError === 401 || fetchError === 404 && <>
            <p className="mb-3 aui-color-text-red">This entry doesn't exist, or you don't have permission to view it.</p>
            <p className="aui-color-text-red">Please do not enter random numbers into the addres bar. Thank you.</p>
          </>}
          {fetchError !== 401 && fetchError !== 404 &&
            <p className="aui-color-text-red">There was an error fetching the data. Please try reloading the page.</p>
          }
        </div>}

      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryPreorder);
