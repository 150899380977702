import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="aui-color-gray20 aui-color-text-light py-5 mt-5">
        <div className="container">
          <p className="text-end">{process.env.RAZZLE_BUILDNUM}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
